import styles from './manual.module.scss';
import YtSvg from './ytSvg';

export default function Manual() {
	function goToLink(link) {
		window.open(link, '_blank');
	}

	return (
		<div className={styles.Manual}>
			<div className={styles.bgHeader}>
				<img
					src="/PocketPuzzle/ManualdeMontagem/bg.png"
					alt="Imagem de Header"
				/>
			</div>
			<div className={styles.wrapperVideos}>
				<p>Assista !</p>
				<div className={styles.wrapperBtns}>
					<button
						onClick={() => goToLink('https://youtu.be/9CbMp6GOA4k')}
						className={styles.btn}
					>
						<YtSvg />
						Montagem
					</button>
					<button
						onClick={() => goToLink('https://www.youtube.com/watch?v=PY71l_Kf4QU')}
						className={styles.btn}
					>
						<YtSvg />
						Resolução
					</button>
				</div>
			</div>

			<div className={styles.content}>
				<span style={{ color: 'red' }}>
					<i>
						{' '}
						Para montar o jogo corretamente, siga o manual com
						atenção, começando pelo último compartimento.
					</i>
				</span>
				<p>Compartimento discos ícones </p>
				<div className={styles.containerPassword}>
					<p>Senha</p>
					<img
						className={styles.imgPassword}
						src="/PocketPuzzle/ManualdeMontagem/image1.png"
						alt="Imagem 1"
					/>
				</div>
				<span>Abaixo os itens que vão no compartimento:</span>
				<img
					src="/PocketPuzzle/ManualdeMontagem/image2.png"
					alt="Imagem 2"
				/>
				<p>Compartimento laterais</p>
				<span>
					As duas gavetas ficam protegidas com uma capa. Então após
					colocar as peças no compartimento, coloca a capa para
					esconder
				</span>
				<img
					src="/PocketPuzzle/ManualdeMontagem/image3.png"
					alt="Imagem 3"
				/>
				<p>Compartimento discos números</p>
				<span>
					Antes de seguir para as peças dentro do compartimento,
					precisa seguir esses dois passos:
				</span>
				<img
					src="/PocketPuzzle/ManualdeMontagem/image4.png"
					alt="Imagem 4"
				/>
				<span>
					Passo 1: volte com a bolinha de canhão e use o baú para
					transporta-la até a ponta do labirinto, onde está marcado
					com o "X".
				</span>
				<img
					src="/PocketPuzzle/ManualdeMontagem/image5.png"
					alt="Imagem 5"
				/>
				<span>
					Passo 2: Enrole e Insira a cifra de Cessar no compartimento
					oculto sob o sistema de cadeado, utilizando a peça fornecida
					no compartimento para empurrar ao máximo a cifra.
				</span>
				<div className={styles.containerPassword}>
					<p>Senha</p>
					<img
						className={styles.imgPassword}
						src="/PocketPuzzle/ManualdeMontagem/image6.png"
						alt="Imagem 6"
					/>
				</div>
				<span>Abaixo os itens que vão no compartimento:</span>
				<img
					src="/PocketPuzzle/ManualdeMontagem/image7.png"
					alt="Imagem 7"
				/>
				<p>Gaveta escondida</p>
				<span>
					Na gaveta, há um compartimento secreto que se revela ao
					puxar a base para fora. Nesse compartimento, deve ser
					colocado:
				</span>
				<img
					src="/PocketPuzzle/ManualdeMontagem/image8.png"
					alt="Imagem 8"
				/>
				<span>
					Na gaveta secreta, devem ser guardadas as cartas restantes
					do jogo, depois encaixando na parte de baixo da caixa:
				</span>
				<img
					src="/PocketPuzzle/ManualdeMontagem/image9.png"
					alt="Imagem 9"
				/>
				<p>Caveira e chave da gaveta</p>
				<span>
					Coloque a chave pequena, usada para abrir a gaveta secreta,
					no espaço localizado abaixo da caveira.
				</span>
				<img
					src="/PocketPuzzle/ManualdeMontagem/image10.png"
					alt="Imagem 10"
				/>
				<span>
					<b>
						Prontinho! Agora o Pocket Puzzle está preparado para
						jogar e impressionar seus amigos — seja em um churrasco,
						uma festa ou enquanto saboreia um bom rum. AArrrrr!!!!!
					</b>
				</span>
				<img
					className={styles.footerImage}
					src="/PocketPuzzle/ManualdeMontagem/footer.png"
					alt="Imagem footer"
				/>
			</div>
		</div>
	);
}
