/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import styles from './guide.module.scss';
import { LogoChave, LogoGame, PDFLogo } from './icons';

export default function Guide() {

	const downloadManual = () => {
		const pdfPath = "/investigadores/Livro-Regras-2.5v.pdf"; 
		const link = document.createElement("a");
		link.href = pdfPath;
		link.download = "Livro de Regras 2.5v.pdf";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link); 
	  };
	


	return (
		<div className={styles.investigadores}>
			<div className={styles.header}>
				<img
					className={styles.bgImage}
					src="/investigadores/backGround.png"
				/>
				<div className={styles.wrapperLogos}>
					<LogoChave className={styles.cm} />
					<LogoGame className={styles.game} />
				</div>
			</div>
			<div className={styles.body}>
				<div className={styles.wrapperBtn}>
					<h3>Manual do Investigadores</h3>
					<button onClick={downloadManual}>
						<PDFLogo />
						Baixar manual em pdf
					</button>
				</div>
				<div className={styles.updatesWraper}>
					<h3>Atualizações</h3>
					<div className={styles.updates}>
						V 2.5 - 19.03
						<li>
							Atualização manual com novas regras de preparação.
						</li>
						<li>Introdução de mecânica de tabuleiro.</li>
						<li>
							Introdução de nova mecânica na parte de descobrir
							evidências.
						</li>
					</div>
				</div>
			</div>
		</div>
	);
}
