import styles from './velhoPirata.module.scss';
 
export default function VelhoPirata() {
    return (
        <div className={styles.VelhoPirata}>
            <div className={styles.bgHeader}>
                <img src='/PocketPuzzle/VelhoPirata/bg.png' alt='Capa do Velho Pirata' />
            </div>
            <div className={styles.content}>
                <p>Componentes</p>
                <img src="/PocketPuzzle/VelhoPirata/image1.png" alt="Imagem dos Componentes" />

                <p>Preparação</p>
                <span>
                    1. A gaveta principal vai ser o tabuleiro do jogo;<br />
                    2. Cada jogador escolhe uma das duas cores disponíveis e reúne todas as peças correspondentes à sua escolha;<br />
                    3. Os jogadores devem decidir quem começará — seja por sorteio, acordo mútuo ou, se houver, o vencedor da rodada anterior inicia o jogo;<br />
                    4. O jogador que perdeu escolhe uma das 9 casas do tabuleiro e coloca o baú sobre ela. Essa casa ficará bloqueada até o momento da fase <b>Espadas Cruzadas</b>, impedindo qualquer peça de ser colocada ali.
                </span>
                <span style={{ color: 'red' }}><i>As letras e números no tabuleiro são do jogo base da Caixa e <b>não serão usadas para o jogo do Velho Pirata.</b></i></span>

                <p>Como jogar</p>
                <span>
                    1. Cada jogador, iniciando pelo primeiro jogador, escolhe uma das suas peças e coloca em um espaço vazio no tabuleiro, passando a vez para o próximo jogador, até as peças acabarem ou um dos jogadores ganhar.<br />
                    2. O objetivo do jogo é fazer uma <b>Sequência Real antes do adversário.</b>
                </span>
                <img src='/PocketPuzzle/VelhoPirata/image2.png' alt='Imagem de Como jogar' />
                <span>
                    3. Ambas as cores podem formar uma Sequência Real. Aquele que possuir mais da sua cor na sequência, ganha o jogo. Exemplo:
                </span>
                <img src='/PocketPuzzle/VelhoPirata/image3.png' alt='Imagem de Como Jogar 2' />
                <span>
                    4. A sequência só pode ser formada em direções ortogonais, ou seja, exclusivamente na horizontal ou vertical.<br />
                    5. Caso feche um empate, o jogo continua até finalizar as peças e não haja um vencedor. Se isso acontecer, a fase <b>Espadas Cruzadas</b> acontece.
                </span>

                <p>Espadas cruzadas</p>
                <span>
                    1. O jogador que perdeu a escolha na preparação deve RETIRAR O BAÚ, liberando a casa para a continuação do jogo.<br />
                    2. Agora é a hora decisiva! Os jogadores devem mover suas peças estrategicamente, buscando formar uma <b>Sequência Real</b> e conquistar a vitória.<br />
                    3. Começando pelo primeiro jogador, ele deve escolher uma de suas peças e movê-la para a casa vazia, anteriormente ocupada pelo baú. O segundo jogador faz a mesma coisa, mas agora no espaço vazio que abriu.<br />
                    </span>
                    <span style={{ color: 'red' }}><i>Atenção: a peça escolhida não precisa estar ao lado da casa vazia — qualquer peça do tabuleiro pode ser movida, não importa a distância.</i><br /></span>
                
                    <span>
                    4. O jogo termina assim que um dos jogadores formar uma Sequência Real. O vencedor será aquele que tiver mais peças na sequência formada.<br />
                    <b>Que vença o pirata mais habilidoso. AARRRR!!!</b>
                </span>

                <div className={styles.footerImage}>
                    <img src='/PocketPuzzle/VelhoPirata/footer.png' alt='Imagem de Footer' />
                </div>
            </div>
        </div>
    );
}
