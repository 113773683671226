import React, { useState } from 'react';
import styles from './modalConfirm.module.scss';

export default function ModalConfirm({
	isOpen,
	bodyText,
	onConfirm,
	onReject
}) {
	const [modalIndex, setModalIndex] = useState(0);

	if (!isOpen) return;

	return (
		<>
			<div className={styles.backdrop}></div>
			<div className={styles.modal}>
				<>
					<p>{bodyText}</p>
					<div className={styles.buttonGroup}>
						<button
							onClick={onConfirm}
							className={styles.confirmButton}
						>
							SIM
						</button>
						<button
							onClick={onReject}
							className={styles.cancelButton}
						>
							NÃO
						</button>
					</div>
				</>
			</div>
		</>
	);
}
